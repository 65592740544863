import React, { useState, useEffect } from "react";

const Search = ( { count, categories, getSearchTerm, getSelectedCategories }) => {
  const [blogCategories] = useState([...categories]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const updateCategories = category => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories(currentCategories => [...currentCategories, category]);
    } else {
        setSelectedCategories(selectedCategories.filter(string => string != category))
    }
  };

  useEffect(() => {
    getSelectedCategories( selectedCategories )

  }, [selectedCategories]);

  return (
    <>
      <div className="Search mb-3">
        <div className="row mb-2 mt-3 d-flex justify-content-start justify-content-xs-between justify-content-sm-start">
          {blogCategories.map((category, i) => {
            return (
              <a
                key={i}
                className={`Category btn btn-light mr-md-2 ${
                  selectedCategories.includes(category) ? `selected` : ``
                }`}
                onClick={() => updateCategories(category)}
              >
                {category}
              </a>
            );
          })}
        </div>
        <div className="row">
          <div className="col-9 col-md-10 col-md-8 p-0">
            <input
              className="rounded border-0 bg-light- bg-dark- text-light p-3 w-100"
              type="text"
              placeholder="Filter posts by term(s)"
              onChange={(e) => getSearchTerm(e.target.value)}
            />
          </div>
          <div className="col col-md-1 p-0- d-flex align-items-center justify-content-center">
            <span className="lead text-center font-weight-bold">{count}</span>
          </div>
        </div>
      </div>
      <hr className="w-100 mt-n1" />
    </>
  );
};

export default Search;
