import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Navigation from "../components/Navigation/Navigation";
import Main from "../components/Main/Main";
import Content from "../components/Content/Content";
import Title from "../components/Title/Title";
import Page from "../components/Page/Page";
import Advertisement from "../components/Advertisement/Advertisement";
import Search from "../components/Search/Search";
import Post from "../components/Post/post";
import Footer from "../components/Footer/Footer";

const BlogPage = props => {
  const { allContentfulBlogPost, allContentfulBlogPostCategories } = props.data;

  // State
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [displayPosts, setDisplayPosts] = useState([]);

  const getSearchTerm = term => {
    setSearchTerm(term);
    
  };

  const getSelectedCategories = categories => {
    if (selectedCategories != categories) {
      setSelectedCategories(categories);
    }
    
  };

  const getDisplayPosts = () => {
    let filteredPosts = allContentfulBlogPost.edges;
    if (selectedCategories.length > 0) {
      
      filteredPosts = filteredPosts.filter(({ node }) =>
        node.categories.includes(...selectedCategories)
      );
    }
  
    if (searchTerm != 0 && searchTerm != "") {
      filteredPosts = filteredPosts.filter(({ node }) =>
        node.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return filteredPosts;
  };

  useEffect(() => {
    // effect
    setDisplayPosts(getDisplayPosts());
    // return () => {
    //   cleanup
    // };

    // console.log(`displayed posts: `, displayPosts);
    // console.log(`searchTerm: `, searchTerm);
    // console.log(`selectedCategories: `, selectedCategories);
  }, [searchTerm, selectedCategories]);

  return (
    <>
      <SEO title="Blog" />
      <Layout>
        <Navigation />
        <Main>
          <Content>
            <Title title="blog" />
            <Page>
              <Advertisement />
              <div className="container d-flex flex-column">
                <Search
                  count={displayPosts.length}
                  categories={
                    allContentfulBlogPostCategories.edges[0].node.categories
                  }
                  getSearchTerm={getSearchTerm}
                  getSelectedCategories={getSelectedCategories}
                />
                <ul className="list-unstyled row">
                  {displayPosts.map(({ node }, i) => (
                    <Post key={i} post={node} />
                  ))}
                </ul>
              </div>
              <Advertisement />
            </Page>
          </Content>
          <Footer />
        </Main>
      </Layout>
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query pageQuery {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          title
          published(formatString: "MM-DD-YYYY")
          slug
          categories
          bannerImage {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          excerpt {
            excerpt
          }
          postBody {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
    allContentfulBlogPostCategories {
      edges {
        node {
          categories
        }
      }
    }
  }
`;
