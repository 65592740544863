import React from "react";
import { Link } from "gatsby";

const Post = ({post}) => {
  
  const { bannerImage, slug, title, published, excerpt } = post
  const image = bannerImage.fluid.src || null;

  return (
    <li className="w-100">
      <Link to={slug} className="Post media p-3 mb-3 rounded bg-light d-flex align-items-center">
        { image ? (
          <img src={ image } className="mr-3 d-none d-md-block rounded" alt="" />
        ) : (
            ``
          ) }
        <div className="media-body">
          <div className="d-flex justify-content-between">
            <h5 className="mt-0 mb-1">
              { title }
            </h5>
            <p className="text-right text-muted d-md-none font-italic">
              { published }
            </p>
          </div>
          <p>{ excerpt.excerpt } <span className="excerptEllipsis">...</span> <span className="text-right float-right text-muted d-none d-md-block font-italic">{ published }</span></p>
        </div>
      </Link>
    </li>
  );
};

export default Post;
